import { Link } from "gatsby"
import React from "react"
import OutlinedLogo from "../../images/svgs/smallOutlinedLogo.svg"
import NavMenu from "../CoreUI/NavMenu"

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
  return (
    <div className="flex items-center justify-between border-b border-kelp-border h-12 mx-2">
      <Link to="/" className="flex items-center">
        <OutlinedLogo className="mx-2" />
        <h3
          className="font-yoga text-kelp-header text-lg"
          style={{ lineHeight: 1 }}
        >
          Whole Food Nutrition
        </h3>
      </Link>
      <NavMenu />
    </div>
  )
}
export default NavBar
