import React from "react"
import OutlinedLink from "../components/CoreUI/OutlinedLink"
import NavBar from "../components/Home/NavBar"
import SEO from "../components/seo"
import StrokeSvg from "../images/svgs/stroke404.svg"
import KingAvo from "../../static/king_avo.png"
import Footer from "../components/QuickSearch/Footer"

const NotFoundPage = () => (
  <div
    className="min-h-screen flex flex-col overflow-x-hidden"
    style={{ backgroundColor: "#F4FAE8" }}
  >
    <SEO title="404: Not found" />
    <div className="relative" style={{ backgroundColor: "#FBFFF2" }}>
      <div
        className="absolute"
        style={{
          left: 0,
          zIndex: 0,
          overflow: "hidden",
          width: "100%",
          height: "250px",
          bottom: "-250px",
        }}
      >
        <div
          className="absolute"
          style={{
            backgroundColor: "#FBFFF2",
            height: "100%",
            width: "2000px",
            borderRadius: "0 0 100% 100%",
            left: "50%",
            transform: `translateY(-125px) translateX(-50%)`,
          }}
        ></div>
      </div>
      <div
        className="relative mx-auto px-4 pt-2 z-10"
        style={{ maxWidth: 900 }}
      >
        <NavBar />
        <div className="flex items-center mx-auto pt-16 flex-col md:flex-row">
          <h1
            className="font-yoga text-kelp-header md:mr-12 mb-10"
            style={{ fontSize: 167, lineHeight: 1 }}
          >
            404
          </h1>
          <h2 className="text-kelp-header" style={{ fontSize: 38 }}>
            Sorry, we couldn’t find what you were looking for.
          </h2>
        </div>
        <div className="flex justify-center my-4 absolute w-full">
          <OutlinedLink to="/" text="Back Home" color="green" />
        </div>
      </div>
    </div>
    <div
      className="flex items-center pt-48 justify-end px-8 pb-16 mx-auto"
      style={{ maxWidth: 900 }}
    >
      <div className="relative mr-16">
        <p
          className="text-kelp-text font-bold absolute text-right"
          style={{ fontSize: 24, width: 250, right: -20, top: -30 }}
        >
          All is not lost, you’ve found King Avo!
        </p>
        <StrokeSvg />
      </div>
      <img src={KingAvo} />
    </div>
    <div className="flex-1" />
    <Footer />
  </div>
)

export default NotFoundPage
