import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import React from "react"
import { connect } from "react-redux"
import FooterLogo from "../../images/svgs/footerLogoGreen.svg"
import HeartIcon from "../../images/svgs/heart.svg"
import { openAboutModal } from "../../state/slices/globalUI"

interface FooterProps {
  openAboutModal: ActionCreatorWithoutPayload<string>
  maxWidth?: number
}

const Footer: React.FC<FooterProps> = ({ openAboutModal, maxWidth }) => {
  return (
    <footer
      className="bg-kidnapper-bg block relative fadeIn"
      style={{ borderTop: `1px solid #D4DBC5` }}
    >
      <div
        className="py-3 pl-6 mx-auto flex items-center text-sm mx-auto justify-between flex-col md:flex-row"
        style={{ maxWidth: maxWidth }}
      >
        <div className="flex items-center mb-3 md:mb-0">
          <FooterLogo className="mr-2" />
          <span className="text-smm text-kidnapper-subtext">
            © 2021 Whole Food Nutrition. All Rights Reserved.
          </span>
        </div>
        <div className="flex items-stretch md:items-center flex-col md:flex-row">
          <a
            className="px-6 text-smm text-kidnapper-text hover:underline py-2 md:py-0 border-b md:border-0 md:border-r border-sushi-border block text-center"
            href="mailto:miles.crighton@gmail.com?subject=Whole%20Food%20Nutrition"
            target="_blank"
          >
            Contact
          </a>
          <button
            className="px-6 text-smm text-kidnapper-text hover:underline py-2 md:py-0 border-b md:border-0 md:border-r border-sushi-border focus-visible"
            onClick={() => openAboutModal()}
          >
            About
          </button>
          <div className="flex items-center py-2 md:py-0">
            <span className="text-smm text-kidnapper-text pl-6">Made with</span>
            <HeartIcon className="mx-1" style={{ width: 10 }} />
            <span className="text-smm text-kidnapper-text pr-6">
              by Miles Crighton
            </span>
          </div>
        </div>
        {/* <Link
          to=""
          className="mx-4 text-smm text-lemongrass-text hover:underline"
        >
          Contact
        </Link> */}
      </div>
    </footer>
  )
}

const mapDispatch = {
  openAboutModal,
}

export default connect(null, mapDispatch)(Footer)
