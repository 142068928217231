import { Link } from "gatsby"
import React, { useState } from "react"
import { CSSProperties } from "styled-components"
import "./ripple.css"

interface ButtonProps {
  color?: "purple" | "pink" | "green"
  text: string
  to: string
}

const OutlinedLink: React.FC<ButtonProps> = ({
  text,
  color = "purple",
  to,
}) => {
  const [hovered, setHovered] = useState(false)
  const styles = {
    purple: {
      outline: "#C8B1D9",
      text: "#705980",
      ripple: "#fbf6ff",
    },
    pink: {
      text: "#7B4E67",
      outline: "#C999B4",
      ripple: "#fff5fb",
    },
    green: {
      text: "#828E6C",
      outline: "#BBCB9C",
      ripple: "#fcfff5",
    },
  }
  return (
    <Link to={to}>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="relative font-bold rounded-4 px-8 py-2 text-base border-box overflow-hidden inline-block"
        style={{
          // width: 200,
          // height: 40,
          overflow: "hidden",
          border: `1px solid ${styles[color].outline}`,
          color: styles[color].text,
        }}
      >
        <div
          className={`ripple ${hovered ? `ripple--active` : ``}`}
          style={{ "--ripple-color": styles[color].ripple } as CSSProperties}
        />
        <span className="relative mx-auto text-center">{text}</span>
      </div>
    </Link>
  )
}
export default OutlinedLink
